<template>
  <div>
    <PagePreLoader :loading="dataLoading"/>

    <div v-if="!dataLoading" id="page-data">

      <v-system-bar
        class="mt-5 invoice-page-system-bar"
        color="transparent"
        height="30"
      >
        <div class="d-flex justify-space-between" style="width: 100%">
          <v-btn
            :to="{ name : routeMap.user.order.name }"
            color="grey"
          >
            <v-icon left>mdi-arrow-left</v-icon>
            Back to orders
          </v-btn>
          <v-btn
            color="primary"
            @click="showReturnForm = !showReturnForm"
            outlined
          >
            Issue with order
          </v-btn>
        </div>
      </v-system-bar>

      <div class="py-3">
        <div v-if="invoice" id="invoiceHolder">
          <div id="invoice">
            <div id="invoice-mid">
              <div class="inv-info">
                <h2>{{ customer.name }}</h2>
                <p> {{ customer.email }} <br>
                  {{ customer.phone }}<br>
                  <span v-html="statusBadge(invoice.status)"></span>
                </p>
              </div>

              <div id="project">
                <h2>Delivery Details</h2>
                <p>
                  House no: {{ customer.house_no }},
                  Road no: {{ customer.road }}, <br>
                  City: {{ customer.city }},
                  County: {{ customer.county }}
                  Postcode: {{ customer.post_code }}
                </p>
              </div>

            </div>
            <div id="invoice-bot">

              <div id="table">
                <v-simple-table style="width: 100%">
                  <tr class="table-title">
                    <td class="item"><h2>Product</h2></td>
                    <td class="Hours"><h2>Quantity</h2></td>
                    <td class="Rate"><h2>Price</h2></td>
                    <td class="Hours"><h2>Status</h2></td>
                    <td class="subtotal"><h2>Sub-total</h2></td>
                  </tr>

                  <tr v-for="(product, index) in products" :key="'product'+index" class="service">
                    <td class="table-item" style="max-width: 10%"><p class="item-text">{{ product.name }}</p></td>
                    <td class="table-item"><p class="item-text">{{ product.qty }}</p></td>
                    <td class="table-item"><p class="item-text">{{ product.price | currency }}</p></td>
                    <td class="table-item"><p class="item-text">{{ product.status }}</p></td>
                    <td class="table-item"><p class="item-text">{{ product.total | currency }}</p></td>
                  </tr>

                  <tr class="service price-info">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="Rate"><h2>Sub Total</h2></td>
                    <td class="payment"><h2>{{ price.subtotal | currency }}</h2></td>
                  </tr>
                  <tr class="service price-info">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="Rate"><h2>Delivery Charge</h2></td>
                    <td class="payment"><h2>{{ price.delivery_charge | currency }}</h2></td>
                  </tr>
                  <tr class="service price-info">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="Rate"><h2> Discount </h2></td>
                    <td class="payment"><h2>{{ price.discount | currency }}</h2></td>
                  </tr>
                  <tr class="table-title">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="Rate"><h2>Total</h2></td>
                    <td class="payment"><h2>{{ price.total | currency }}</h2></td>
                  </tr>
                </v-simple-table>
                <!--    Disabled for now, will implement later   -->
                <!--                <v-btn-->
                <!--                  :outlined="showTimeline"-->
                <!--                  class="text-center mt-3 mb-0"-->
                <!--                  color="info" @click="showTimeline = !showTimeline"-->
                <!--                > {{ showTimeline ? 'Hide Order Status' : 'View Order Status' }}-->
                <!--                </v-btn>-->
              </div>


<!--              <div id="legal-copy">-->
<!--                <p class="legal"><strong>Thank you for your business!</strong> Payment is expected within 31 days;-->
<!--                  please-->
<!--                  process this invoice within that time. There will be a 5% interest charge per month on late invoices.-->
<!--                </p>-->
<!--              </div>-->
            </div>

            <!--    Timeline      -->
            <div v-if="showTimeline" class="timeline-container my-3">
              <v-fade-transition>
                <v-sheet>
                  <v-timeline
                    dense
                  >
                    <v-timeline-item
                      v-for="(timeline, index) in timelines"
                      :key="'timeline'+ index"
                      :small="index === (timelines.length - 1)"
                      color="primary"
                    >
                      <v-row align="center" align-content="center" class="pt-1">
                        <v-col cols="3">
                          <strong>{{ timeline.created_at | date }}</strong>
                        </v-col>
                        <v-col>
                          <strong v-html="statusBadge(timeline.status)"></strong>
                        </v-col>
                      </v-row>
                    </v-timeline-item>
                  </v-timeline>
                </v-sheet>
              </v-fade-transition>
            </div>
          </div>
        </div>
        <div v-else>No Invoice found</div>
      </div>
    </div>

    <!--    <v-dialog v-model="showReturnForm" max-width="600" persistent scrollable>-->
    <!--      <v-sheet>-->
    <OrderReturnForm
      v-model="showReturnForm"
      :order="invoice"
      :products="products"
      @close="showReturnForm = !showReturnForm"
    />
    <!--      </v-sheet>-->
    <!--    </v-dialog>-->
  </div>
</template>

<script>
import { userOrders } from '@/api/user';
import { mapActions } from 'vuex';
import { collect } from 'collect.js';
import OrderReturnForm from '@/components/User/OrderReturn/OrderReturnForm';
import routeMap from '@/utils/routeMap';
import PagePreLoader from '@/components/Utils/PagePreLoader';

export default {
  name: 'Invoice',
  components: { PagePreLoader, OrderReturnForm },
  data: () => ({
    routeMap,
    dataLoading: false,
    orders: [],
    invoice: {},
    showTimeline: false,
    showReturnForm: false,
  }),
  computed: {
    timelines() {
      return this.invoice?.timeline;
    },
    customer() {
      return {
        name: this.invoice?.customer?.name,
        email: this.invoice?.customer?.email,
        phone: this.invoice?.customer?.phone,
        house_no: this.invoice?.customer?.house_no,
        road: this.invoice?.customer?.road,
        city: this.invoice?.customer?.city,
        county: this.invoice?.customer?.county,
        post_code: this.invoice?.customer?.post_code,
      };
    },
    products() {
      return this.invoice?.products;
    },
    price() {
      return {
        subtotal: this.invoice?.subtotal,
        delivery_charge: this.invoice?.delivery_charge,
        discount: this.invoice?.discount,
        total: this.invoice?.total,
      };
    },
  },

  mounted() {
    this.dataLoading = true;
  },

  methods: {

    ...mapActions({
      orderData: 'user/userOrders'
    }),

    async getInvoice() {
      this.dataLoading = true;
      let invoiceID = Number(this.$route.params.invoice);
      let { data } = await userOrders();
      await this.orderData(data.data);
      this.orders = data.data;
      let orderList = collect(this.orders);
      this.invoice = orderList.where('id', invoiceID).first();
      this.dataLoading = false;
    }
  },
  async beforeMount() {
    await this.getInvoice();
  },
};
</script>

<style lang="scss" scoped>
.invoice-page-system-bar {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}

.service.price-info {
  border-color: transparent;

  td:not(.Rate):not(.payment) {
    border-color: transparent;
  }

  .rate {
    border-left-color: #EEEEEE;
  }
}

#invoiceHolder h1 {
  font-size: 1.5em;
  color: #222;
}

#invoiceHolder h2 {
  font-size: .9em;
}

#invoiceHolder h3 {
  font-size: 1.2em;
  font-weight: 300;
  line-height: 2em;
}

#invoiceHolder p {
  font-size: .9rem;
  color: #666;
  line-height: 1.4;
  padding: .5rem 0;
  margin-bottom: 0;
}

#invoiceHolder {
  width: 100%;
  height: 100%;
}

.client-logo {
  float: left;
  height: 60px;
  width: 60px;
}

#invoice {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 700px;
  background: #FFF;
}

[id*='invoice-'] { /* Targets all id with 'col-' */
  border-bottom: 1px solid #EEE;
  padding: 30px;
}

@media screen and (max-width: 500px) {
  [id*='invoice-'] {
    padding: 10px;
  }
}

#invoice-top {
  min-height: 120px;
}

#invoice-mid {
  min-height: 120px;
}

#invoice-bot {
  min-height: 250px;
}

.inv-info {
  display: block;
  float: left;
  margin-left: 20px;
}

.title {
  float: right;
}

.title p {
  text-align: right;
}

#project {
  margin-left: 52%;
}

table {
  width: 100%;
  border-collapse: collapse;
}

td {
  padding: 5px 0 5px 15px;
  border: 1px solid #EEE
}

.table-title {
  padding: 5px;
  background: #EEE;
}

.service {
  border: 1px solid #EEE;
}

.item {
  width: 50%;
}

.item-text {
  font-size: .9em;
}

#legal-copy {
  margin-top: 30px;
}

form {
  float: right;
  margin-top: 30px;
  text-align: right;
}

.legal {
  width: 70%;
}
</style>
